import React, { useEffect, useState } from "react";

/* Theme variables */
import "../../theme/variables.css";

import "../../theme/UserImportRow.css";

import { SelectedLocation } from "../../interfaces/Location";
import { User } from "../../interfaces/User";
import { usePortal } from "../PortalProvider";
import UserLocationsPopover from "./UserLocationsPopover";
import UserRolesPopover from "./UserRolesPopover";

interface UserImportRowProps {
  user: User;
  index: number;
  onUpdateUser: (index: number, updatedUser: User) => void;
  onUpdateSelected: Function;
  shouldValidateUser: boolean;
  locationId?: string;
  allUsersIsChecked: boolean;
}

const UserImportRow: React.FC<UserImportRowProps> = ({ user, index, onUpdateUser, onUpdateSelected, shouldValidateUser, locationId, allUsersIsChecked }) => {
  const portal = usePortal();
  const invalidControlStyle = "user-control-invalid";

  const [emailAddress, setEmailAddress] = useState(user.Email);
  const [isValid, setIsValid] = useState(emailIsValid(user.Email) && portal!.State.locations.some((x: SelectedLocation) => !!x.selected));
  const [isChecked, setIsChecked] = useState(false);
  const [emailStyle, setEmailStyle] = useState("");

  useEffect(() => {
    if (user.Email !== emailAddress) {
      setEmailAddress(user.Email);
    }
  }, [user.Email]);

  useEffect(() => {
    if (isValid) {
      setIsChecked(allUsersIsChecked);
    }
  }, [isValid, allUsersIsChecked]);

  useEffect(() => {
    if (shouldValidateUser && !emailIsValid(emailAddress)) {
      setEmailStyle(invalidControlStyle);
    } else {
      setEmailStyle("");
    }
  }, [shouldValidateUser, emailAddress]);

  useEffect(() => {
    setIsChecked(user.SelectedToImport || false);
  }, [user.SelectedToImport]);

  const onUserSelected = (checkedValue: boolean) => {
    if (isValid) {
      setIsChecked(checkedValue);

      let updatedUser: User = { ...user };
      updatedUser.SelectedToImport = checkedValue;

      onUpdateSelected(updatedUser.SelectedToImport);
      onUpdateUser(index, updatedUser);
    } else {
      if (!emailIsValid(emailAddress)) {
        setEmailStyle(invalidControlStyle);
      }
    }
  };

  const onUserChanged = (updatedUser: User) => {
    let locationsAreValid = updatedUser.Locations.length > 0;
    let userIsValid = checkIfUserIsValid(updatedUser.Email, locationsAreValid);
    updatedUser.Valid = userIsValid;
    onUpdateUser(index, updatedUser);
  };

  const updateEmail = (email: string) => {
    setEmailAddress(email);
  };

  const checkIfUserIsValid = (email: string, selectedLocationsAreValid: boolean) => {
    let isEmailValid = emailIsValid(email);

    let userIsValid = isEmailValid && selectedLocationsAreValid;
    setIsValid(userIsValid);
    if (isEmailValid) {
      setEmailStyle("");
    }

    return userIsValid;
  };

  function emailIsValid(email: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  return (
    <tr className="user-lineItem">
      <td className="user-selected">
        <div className="checkboxHolder">
          <label className="checkbox checkmarkEnabled">
            <input type="checkbox" checked={isChecked} onChange={(e) => onUserSelected(!!e.currentTarget.checked)} data-testid="user-import-select" />
            <span className="checkmark" />
          </label>
        </div>
      </td>
      <td className="first-name">{user.FirstName}</td>
      <td className="last-name">{user.LastName}</td>
      <td className="email">
        <input
          value={emailAddress}
          placeholder="Email..."
          className={emailStyle}
          onChange={(e) => updateEmail(e.currentTarget.value)}
          data-testid="user-import-email"
        />
      </td>
      <td className="role">
        <UserRolesPopover
          user={user}
          onUserChanged={onUserChanged}
        />
      </td>
      {!locationId && (
        <td className="locations">
          <UserLocationsPopover
            user={user}
            index={index}
            onUserChanged={onUserChanged}
          />
        </td>
      )}
    </tr>
  );
};

export default UserImportRow;
